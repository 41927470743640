import { ISideBar } from '@/types/types';

export const links: { [key: string]: Array<ISideBar | { label: string }> } = {
  users: [
    {
      label: 'General',
    },
    {
      link: 'account.dashboard',
      icon: 'widgets',
      text: 'Dashboard',
    },
    {
      link: 'account.events.list',
      icon: 'event_note',
      text: 'Events',
      isNew: false,
    },
    {
      link: 'account.events.tickets',
      icon: 'confirmation_number',
      text: 'Tickets',
      isNew: false,
    },
    {
      link: 'account.events.favorites',
      icon: 'favorite',
      text: 'Favorites',
      isNew: false,
    },
    // {
    //   label: 'Settings',
    // },
    // {
    //   link: 'account.profile',
    //   icon: 'face',
    //   text: 'My Profile',
    //   isNew: false,
    // },
  ],
};
